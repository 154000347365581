import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import {decode} from "html-entities";

const NewsContainer = styled.div`
  width: 100%;
  min-height: 200px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e7e7e8;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const NewsHeader = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  background: #fafafa;
  font-weight: 600;
  font-size: 16px;
`;

const NewsList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 8px;
`;

const NewsItem = styled.a`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #eee;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: #f8f9fa;
    .news-title {
      text-decoration: underline;
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

const NewsTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #000;
  line-height: 1.5;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: keep-all;
`;

const NewsInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
`;

const NewsSource = styled.div`
  font-size: 13px;
  color: #666;
  font-weight: 500;
  position: relative;
  padding-right: 8px;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 3px;
    background-color: #666;
    border-radius: 50%;
  }
`;

const NewsDate = styled.div`
  font-size: 13px;
  color: #666;

  &.recent {
    color: #1a6dff;
  }
`;

const NewsSection = ({ keyword }) => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoading(true);
        let searchKeywords = "집회 시위 계엄";
        if (keyword) {
          const trimmedKeyword = keyword.slice(0, 20);
          searchKeywords = `${trimmedKeyword} ${searchKeywords}`;
        }

        const response = await axios.get(`/api/news`, {
          params: {
            query: searchKeywords,
            display: 5,
          },
          timeout: 5000,
        });

        if (!response.data.items) {
          throw new Error("Invalid response format");
        }

        setTotal(response.data.total);
        const cleanedNews = response.data.items.map((item) => ({
          title: decode(item.title.replace(/<\/?b>/g, "")),
          link: item.originallink || item.link,
          description: item.description.replace(/<\/?b>/g, ""),
          pubDate: item.pubDate,
          source: new URL(item.originallink || item.link).hostname.replace(
            "www.",
            ""
          ),
        }));

        setNews(cleanedNews);
      } catch (error) {
        console.error("뉴스 데이터 로딩 실패:", error);
        setNews([]);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
    const interval = setInterval(fetchNews, 3 * 60 * 1000);
    return () => clearInterval(interval);
  }, [keyword]);

  const getTimeAgo = (pubDate) => {
    const now = new Date();
    const published = new Date(pubDate);
    const diffInMinutes = Math.floor((now - published) / (1000 * 60));

    if (diffInMinutes < 60) {
      return `${diffInMinutes}분 전`;
    }

    return new Date(pubDate).toLocaleString("ko-KR", {
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <NewsContainer>
      <NewsHeader>실시간 관련 뉴스 {total > 0 && `(총 ${total}건)`}</NewsHeader>
      <NewsList>
        {loading ? (
          <div style={{ padding: "12px", textAlign: "center" }}>
            뉴스를 불러오는 중...
          </div>
        ) : news.length === 0 ? (
          <div style={{ padding: "12px", textAlign: "center" }}>
            관련 뉴스가 없습니다.
          </div>
        ) : (
          news.map((item, index) => {
            const timeAgo = getTimeAgo(item.pubDate);
            const isRecent = timeAgo.includes("분");

            return (
              <NewsItem
                key={index}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <NewsTitle className="news-title">{item.title}</NewsTitle>
                <NewsInfo>
                  <NewsSource>{item.source}</NewsSource>
                  <NewsDate className={isRecent ? "recent" : ""}>
                    {timeAgo}
                  </NewsDate>
                </NewsInfo>
              </NewsItem>
            );
          })
        )}
      </NewsList>
    </NewsContainer>
  );
};

export default NewsSection;
