import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { parseISO, isAfter, isBefore, format } from "date-fns";
import Papa from "papaparse";
import noIcon from "../assets/no.svg";

const StoreListContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 70%;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  background: #ffffff;
  min-width: 0;

  @media (min-width: 1441px) {
    min-width: 400px;
  }

  @media (max-width: 768px) {
    width: 343px;
    height: 688px;
    max-height: 720px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  border-bottom: 1px solid #eee;
  background: #fafafa;
  font-weight: 600;
  font-size: 16px;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  gap: 8px;
  width: 100%;
  height: calc(100% - 32px);
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 0;

  @media (max-width: 768px) {
    width: 343px;
    height: calc(688px - 32px);
    padding-bottom: 16px;
  }
`;

const StyledSupportCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: calc(100% - 32px);
  min-width: 240px;
  height: 208px;
  border: 1px solid #e7e7e8;
  border-radius: 8px;
  margin: 0 16px;
  margin-bottom: 8px;
  flex-shrink: 0;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    width: 311px;
    margin: 0 16px 8px 16px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  width: 100%;
  height: 40px;
  background: #e9e9e9;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

const StoreInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 22px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const StoreName = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const StatusBadge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  width: ${(props) => (props.isEnded ? "37px" : "48px")};
  height: 22px;
  background: ${(props) => (props.isEnded ? "#111111" : "#007bff")};
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const StatusText = styled.span`
  width: ${(props) => (props.isEnded ? "21px" : "32px")};
  height: 14px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 100%;
  height: 168px;
  background: #ffffff;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: 16px;
  flex: none;
  order: ${(props) => props.order || 0};
  flex-grow: 0;
`;

const Label = styled.span`
  width: 40px;
  height: 16px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const Value = styled.span`
  flex: 1;
  height: 16px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  order: 1;
  text-align: left;
`;

const LoadingMessage = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
`;

const ErrorMessage = styled.div`
  padding: 20px;
  text-align: center;
  color: #ff4444;
`;

const EndReport = styled.button`
  position: relative;
  width: 32px;
  height: 32px;
  background: #f5f7ff;
  border-radius: 32px;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  &:hover {
    background: #edf0f9;

    .tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  &:active {
    background: #007bff;

    img {
      filter: brightness(0) invert(1);
    }
  }
`;

const Tooltip = styled.div`
  position: absolute;
  right: 0;
  top: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  width: 134px;
  height: 24px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
`;

const TooltipText = styled.span`
  width: 118px;
  height: 14px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #ffffff;
`;

const NoIcon = styled.img`
  position: absolute;
  width: 18px;
  height: 21.28px;
  left: 6.28px;
  top: 5.36px;
`;

export const formatDateTime = (dateStr) => {
  if (!dateStr || dateStr.toLowerCase() === "소진시까지") return "소진시까지";
  try {
    const date = parseISO(dateStr);
    return format(date, "yyyy/MM/dd HH:mm");
  } catch {
    return dateStr;
  }
};

const SupportCard = ({ support, onSupportSelect }) => {
  const [isEnded, setIsEnded] = useState(false);
  const [reportCount, setReportCount] = useState(0);

  useEffect(() => {
    const reportData = JSON.parse(
      localStorage.getItem(`report_${support.id}`) ||
        '{"count": 0, "users": []}'
    );
    setReportCount(reportData.count);
    setIsEnded(reportData.count >= 3);
  }, [support.id]);

  const handleReport = () => {
    const getUserId = () => {
      const userAgent = navigator.userAgent;
      const screenResolution = `${window.screen.width}x${window.screen.height}`;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return btoa(`${userAgent}-${screenResolution}-${timezone}`);
    };

    const userId = getUserId();
    const reportKey = `report_${support.id}`;
    const reportData = JSON.parse(
      localStorage.getItem(reportKey) || '{"count": 0, "users": []}'
    );

    if (reportData.users.includes(userId)) {
      alert("이미 신고하셨습니다.");
      return;
    }

    const newCount = reportData.count + 1;
    const newUsers = [...reportData.users, userId];

    localStorage.setItem(
      reportKey,
      JSON.stringify({
        count: newCount,
        users: newUsers,
      })
    );

    setReportCount(newCount);
    if (newCount >= 3) {
      setIsEnded(true);
    }
  };

  return (
    <StyledSupportCard onClick={() => onSupportSelect(support.location)}>
      <CardHeader>
        <StoreInfo>
          <StoreName>{support.location.name}</StoreName>
          <StatusBadge isEnded={isEnded}>
            <StatusText isEnded={isEnded}>
              {isEnded ? "종료" : "진행중"}
            </StatusText>
          </StatusBadge>
        </StoreInfo>
        <EndReport
          onClick={(e) => {
            e.stopPropagation();
            handleReport();
          }}
        >
          <NoIcon src={noIcon} alt="신고" />
          <Tooltip className="tooltip">
            <TooltipText>종료되었다면 눌러주세요</TooltipText>
          </Tooltip>
        </EndReport>
      </CardHeader>
      <CardContent>
        <InfoRow>
          <Label>메뉴</Label>
          <Value>{support.menu}</Value>
        </InfoRow>
        <InfoRow>
          <Label>시작</Label>
          <Value>{formatDateTime(support.startDate)}</Value>
        </InfoRow>
        <InfoRow>
          <Label>기한</Label>
          <Value>{formatDateTime(support.endDate)}</Value>
        </InfoRow>
        <InfoRow>
          <Label>수량</Label>
          <Value>{support.quantity}잔</Value>
        </InfoRow>
        <InfoRow>
          <Label>제한</Label>
          <Value>{support.limit || "없음"}</Value>
        </InfoRow>
        <InfoRow>
          <Label>후원</Label>
          <Value>{support.sponsor}</Value>
        </InfoRow>
        {support.note && (
          <InfoRow>
            <Label>비고</Label>
            <Value>{support.note}</Value>
          </InfoRow>
        )}
      </CardContent>
    </StyledSupportCard>
  );
};

const StoreList = ({ onSupportSelect }) => {
  const [supports, setSupports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const isValidDate = (dateStr) => {
    try {
      if (!dateStr || dateStr.toLowerCase() === "소진시까지") return true;
      const parsed = parseISO(dateStr);
      return !isNaN(parsed.getTime());
    } catch {
      return false;
    }
  };

  const isActiveSupport = (startDate, endDate) => {
    const now = new Date();

    // 시작일 체크
    if (!isValidDate(startDate)) return false;
    const start = parseISO(startDate);
    if (isAfter(start, now)) return false;

    // 종료일 체크
    if (!endDate || endDate.toLowerCase() === "소진시까지") return true;
    if (!isValidDate(endDate)) return false;
    const end = parseISO(endDate);
    return !isBefore(end, now);
  };

  useEffect(() => {
    const fetchSheetData = async () => {
      try {
        setLoading(true);
        setError(null);

        const SHEET_ID = process.env.REACT_APP_GOOGLE_SHEET_ID;
        if (!SHEET_ID) {
          throw new Error("Google Sheet ID가 설정되지 않았습니다.");
        }

        const SHEET_URL = `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:csv`;

        const response = await axios.get(SHEET_URL, {
          headers: {
            "Content-Type": "text/csv;charset=utf-8",
          },
        });

        const rows = Papa.parse(response.data, { header: true });
        const formattedSupports = rows.data.map((row, index) => {
          const startDate = row["시작 날짜"]; // 시작일 컬럼
          const endDate = row["기한"]; // 종료일(기한) 컬럼

          return {
            id: index + 1,
            location: {
              name: row["장소명"] || "",
              address: row["주소"] || "",
              lat: parseFloat(row["lat"]) || 0,
              lng: parseFloat(row["lng"]) || 0,
            },
            menu: row["메뉴"] || "",
            quantity: parseInt(row["수량"]) || 0,
            sponsor: row["키워드(후원자)"] || "",
            startDate: startDate,
            endDate: endDate,
            limit: row["제한"] || "",
            note: row["비고"] || "",
          };
        });

        const validSupports = formattedSupports.filter(
          (support) =>
            support.location.name &&
            support.location.name.trim() !== "" &&
            // support.location.lat !== 0 &&
            // support.location.lng !== 0 &&
            isActiveSupport(support.startDate, support.endDate)
        );

        setSupports(validSupports);
      } catch (error) {
        console.error("스프레드시트 데이터 로딩 실패:", error);
        setError(
          "데이터를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSheetData();
    const interval = setInterval(fetchSheetData, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <StoreListContainer>
        <Header>진행중인 후원</Header>
        <LoadingMessage>데이터를 불러오는 중...</LoadingMessage>
      </StoreListContainer>
    );
  }

  if (error) {
    return (
      <StoreListContainer>
        <Header>진행중인 후원</Header>
        <ErrorMessage>{error}</ErrorMessage>
      </StoreListContainer>
    );
  }

  return (
    <StoreListContainer>
      <Header>진행중인 후원</Header>
      <ScrollContainer>
        {supports.length === 0 ? (
          <LoadingMessage>진행중인 후원이 없습니다.</LoadingMessage>
        ) : (
          supports.map((support) => (
            <SupportCard
              key={support.id}
              support={support}
              onSupportSelect={onSupportSelect}
            />
          ))
        )}
      </ScrollContainer>
    </StoreListContainer>
  );
};

export default StoreList;
