import React, { useEffect, useState, useRef, use } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import Hls from "hls.js";

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  height: 100%;
`;

const MapSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const MapContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 60%;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
`;

const SearchResults = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  border: 1px solid #ddd;
`;

const SearchResultItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  background: white;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #f8f9fa;
  }

  .place-name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 4px;
  }

  .place-address {
    font-size: 12px;
    color: #666;
  }
`;

const SearchContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  position: absolute;
  width: 80%;
  height: 48px;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  border: 1px solid #e7e7e8;
  box-shadow: 86px 183px 81px rgba(0, 0, 0, 0.01),
    48px 103px 68px rgba(0, 0, 0, 0.05), 21px 46px 51px rgba(0, 0, 0, 0.09),
    5px 11px 28px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;

  @media (min-width: 768px) {
    width: 80%;
  }
`;

const SearchInput = styled.input`
  margin: 0 auto;
  width: 100%;
  height: 24px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #111111;
  border: none;
  outline: none;
  background: transparent;

  &::placeholder {
    color: #8d9195;
  }
`;

const CCTVSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 268.5px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const CCTVHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 32px;
  background: #fafafa;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  h3 {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }
`;

const CCTVList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px 8px;
  gap: 12px;
  width: 100%;
  height: 206.5px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  overflow-x: auto;
`;

const CCTVItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 251.1px;
  height: 198.5px;
  border: 1px solid #e7e7e8;
  border-radius: 8px;
  flex: none;
  flex-grow: 0;
`;

const CCTVItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  width: 100%;
  height: 32px;
  background: #f5f7ff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const CCTVTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const CCTVLocation = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #61666b;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const CCTVContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  height: 226.5px;
  background: #ffffff;
`;

const CCTVVideo = styled.div`
  width: 251.1px;
  height: 166.5px;
  background: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;

const CCTVCaption = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #cbcdd6;
  width: 100%;
  padding: 8px 0px 0px 16px;
`;

const VideoPlayer = ({ src, type }) => {
  const videoRef = useRef();

  useEffect(() => {
    if (type === "m3u8" && Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
    }
  }, [src, type]);

  return type === "m3u8" ? (
    <video ref={videoRef} controls />
  ) : (
    <video ref={videoRef} src={src} controls />
  );
};

const MapComponent = ({ selectedLocation, setSelectedLocation }) => {
  const [map, setMap] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [cctvList, setCctvList] = useState([]);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [infowindow, setInfowindow] = useState(null);
  const searchKeywordRef = useRef(true);

  // UTIC API에서 CCTV 데이터 가져오기
  // fetchNearbyCCTVs 함수 수정
  const fetchNearbyCCTVs = async (lat, lng) => {
    try {
      // 백엔드 프록시 서버를 통해 요청
      const response = await axios.get(`/api/cctv`, {
        params: {
          lat: lat,
          lng: lng,
          radius: 1,
        },
      });

      if (response.data && Array.isArray(response.data)) {
        const cctvData = response.data.map((cctv) => ({
          id: cctv.cctvId,
          name: cctv.cctvName,
          address: cctv.address,
          streamUrl: cctv.streamUrl,
          lat: cctv.latitude,
          lng: cctv.longitude,
          distance: calculateDistance(lat, lng, cctv.latitude, cctv.longitude),
        }));

        setCctvList(cctvData.sort((a, b) => a.distance - b.distance));
      }
    } catch (error) {
      console.error("CCTV 데이터 가져오기 실패:", error);
      setCctvList([]);
    }
  };

  const createInfoWindow = (place, marker) => {
    closeInfoWindow();
    const newInfowindow = new window.kakao.maps.InfoWindow({
      content: `
        <div style="padding:10px;width:200px;text-align:center;">
          <strong><a href="${place.place_url}" target="_blank" style="text-decoration:none;color:inherit;">${place.place_name} &#128279;</a></strong>
          <p style="margin-top:5px;font-size:12px;">${place.address_name}</p>
        </div>
      `,
    });

    newInfowindow.open(map, marker);
    setInfowindow(newInfowindow);
  };

  const closeInfoWindow = () => {
    if (infowindow) {
      infowindow.close();
      setInfowindow(null);
    }
  };

  const performKeywordSearch = (searchKeyword, callback) => {
    const ps = new window.kakao.maps.services.Places();

    ps.keywordSearch(searchKeyword.trim(), (data, status) => {
      console.log("검색 상태:", status);
      console.log("검색 결과:", data);

      if (status === window.kakao.maps.services.Status.OK) {
        callback(null, data);
      } else {
        callback(status, null);
      }
    });
  };

  const getPostionFromPlace = (place) => {
    return new window.kakao.maps.LatLng(place.y, place.x);
  };

  const removeCurrentMarker = () => {
    console.log("===removeCurrentMarker===");
    if (currentMarker) {
      currentMarker.setMap(null);
    }
  };

  const createMarker = (place) => {
    removeCurrentMarker();
    console.log("===createMarker===");
    const marker = new window.kakao.maps.Marker({
      position: getPostionFromPlace(place),
      map: map,
    });
    setCurrentMarker(marker);
    return marker;
  };

  const focusMap = (place, level = 3) => {
    map.setCenter(getPostionFromPlace(place));
    map.setLevel(level);
  };

  const updateSelectedLocation = (place) => {
    // 선택한 위치 정보 업데이트
    const locationInfo = {
      lat: parseFloat(place.y),
      lng: parseFloat(place.x),
      name: place.place_name,
      address: place.address_name,
    };

    // 주변 CCTV 검색 위해 selectedLocation 업데이트
    setSelectedLocation(locationInfo);
  };

  useEffect(() => {
    if (!searchKeyword || !searchKeywordRef.current) {
      searchKeywordRef.current = true;
      return;
    }
    handleSearchInputChange(searchKeyword);
  }, [searchKeyword]);

  // 검색 위치가 변경될 때마다 주변 CCTV 검색
  useEffect(() => {
    fetchNearbyCCTVs(37.524563, 126.917391);
    if (!selectedLocation) return;
    setSearchKeyword(selectedLocation.name);
  }, [selectedLocation]);

  // 거리 계산 함수 (Haversine formula)
  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const R = 6371; // 지구 반지름 (km)
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLng = (lng2 - lng1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // 거리 (km)
  };

  // 초기 지도 생성
  useEffect(() => {
    const initializeMap = () => {
      try {
        const container = document.getElementById("map");
        if (!container) {
          console.error("Map container not found");
          return;
        }

        // kakao.maps가 완전히 로드될 때까지 대기
        if (!window.kakao || !window.kakao.maps) {
          setTimeout(initializeMap, 100);
          return;
        }

        const options = {
          center: new window.kakao.maps.LatLng(37.524563, 126.917391),
          level: 3,
        };

        const mapInstance = new window.kakao.maps.Map(container, options);
        setMap(mapInstance);
        setMapInitialized(true);
        console.log("Map initialized successfully");
      } catch (error) {
        console.error("Map initialization error:", error);
      }
    };

    const loadKakaoMapsScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}&libraries=services&autoload=false`;
        script.async = true;

        script.onload = () => {
          window.kakao.maps.load(() => {
            console.log("Kakao Maps SDK loaded");
            resolve();
          });
        };

        script.onerror = (e) => {
          console.error("Error loading Kakao Maps SDK:", e);
          reject(e);
        };

        document.head.appendChild(script);
      });
    };

    const init = async () => {
      try {
        if (!window.kakao || !window.kakao.maps) {
          console.log("Loading Kakao Maps SDK");
          await loadKakaoMapsScript();
        }
        initializeMap();
      } catch (error) {
        console.error("Initialization error:", error);
      }
    };

    init();

    return () => {
      if (map) {
        setMap(null);
      }
    };
  }, []);

  // 검색 처리
  const handleSearch = () => {
    console.log("===handleSearch===");

    // 검색어가 비어있거나, 지도가 초기화되지 않았거나, 지도가 없으면 검색 불가
    if (!searchKeyword.trim() || !mapInitialized || !map) {
      console.log("@@검색 불가");
      return;
    }
    console.log("@@검색 시도:", searchKeyword);

    // 검색어로 검색된 첫번째 장소로 지도 이동 + 마커 생성
    performKeywordSearch(searchKeyword, (error, data) => {
      if (error) {
        console.error("@@검색 실패:", error);
        setSearchResults([]);
        setShowResults(false);
        return;
      } else {
        setSearchResults(data);
        setShowResults(true);

        if (data && data.length > 0) {
          const place = data[0];
          console.log(place);
          // 인포윈도우 닫기
          closeInfoWindow();
          // 기존 마커 제거
          removeCurrentMarker();
          // 새 마커 생성
          createMarker(place);
          // 지도 포커싱
          focusMap(place);
        } else {
          console.warn("@@검색 결과가 없습니다.");
        }
      }
    });
  };

  // 검색어 입력 처리
  const handleSearchInputChange = (value) => {
    //const value = e.target.value;
    console.log("검색어 입력:", value);
    setSearchKeyword(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (value.trim() && value.length >= 2) {
      console.log("검색 타이머 설정");
      const newTimeout = setTimeout(() => {
        handleSearch();
      }, 300);
      setSearchTimeout(newTimeout);
    } else {
      setSearchResults([]);
      setShowResults(false);
    }
  };

  // 장소 선택 처리
  const handlePlaceSelect = (place) => {
    if (!map) return;

    // 기존 마커 제거
    removeCurrentMarker();
    // 새 마커 생성
    const marker = createMarker(place);
    // 인포윈도우 생성
    createInfoWindow(place, marker);
    // 지도 포커싱
    focusMap(place);
    // 검색 결과 숨기기
    setShowResults(false);
    // 검색어 업데이트
    searchKeywordRef.current = false;
    setSearchKeyword(place.place_name);
    // 선택한 장소 정보 업데이트
    updateSelectedLocation(place);
  };

  return (
    <MapWrapper>
      <MapSection>
        <MapContainer>
          <div id="map" style={{ width: "100%", height: "100%" }} />
          <SearchContainer>
            <SearchInput
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              placeholder="장소를 검색하세요"
            />
            {showResults && searchResults.length > 0 && (
              <SearchResults>
                {searchResults.map((place) => (
                  <SearchResultItem
                    key={place.id}
                    onClick={() => handlePlaceSelect(place)}
                  >
                    <div className="place-name">{place.place_name}</div>
                    <div className="place-address">{place.address_name}</div>
                  </SearchResultItem>
                ))}
              </SearchResults>
            )}
          </SearchContainer>
        </MapContainer>
        <CCTVSection>
          <CCTVHeader>
            <h3>국회 주변 CCTV 목록</h3>
          </CCTVHeader>
          <CCTVList>
            {cctvList.length === 0 ? (
              <div>검색된 CCTV가 없습니다.</div>
            ) : (
              cctvList.map((cctv) => (
                <CCTVItem key={cctv.id}>
                  <CCTVItemHeader>
                    <CCTVTitle>{cctv.name.split(" ")[0]}</CCTVTitle>
                    <CCTVLocation>
                      {cctv.name.split(" ").slice(1).join(" ")}
                    </CCTVLocation>
                  </CCTVItemHeader>
                  <CCTVContent>
                    <CCTVVideo>
                      <VideoPlayer src={cctv.streamUrl} type="m3u8" />
                    </CCTVVideo>
                    <CCTVCaption>
                      실제 상황 30초~1분 정도 차이가 날 수 있습니다.
                    </CCTVCaption>
                  </CCTVContent>
                </CCTVItem>
              ))
            )}
          </CCTVList>
        </CCTVSection>
      </MapSection>
    </MapWrapper>
  );
};

export default MapComponent;
