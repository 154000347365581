import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";

const NavContainer = styled.nav`
  width: 100%;
  height: 48px;
  background: #edf0f9;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
`;

const NavContent = styled.div`
  width: 100%;
  max-width: min(1440px, 100%);
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;

  @media (min-width: 1441px) {
    max-width: 90%;
  }
`;

const HomeLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 44px;
  height: 32px;
  background: ${(props) =>
    props.active ? "rgba(97, 102, 107, 0.1)" : "transparent"};
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: ${(props) => (props.active ? "#111111" : "#61666B")};
`;

const AboutLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 92px;
  height: 32px;
  background: ${(props) =>
    props.active ? "rgba(97, 102, 107, 0.1)" : "transparent"};
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  text-decoration: none;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: ${(props) => (props.active ? "#111111" : "#61666B")};
`;

const NavText = styled.span`
  width: ${(props) => (props.home ? "12px" : "60px")};
  height: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const Navbar = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isAboutPage = location.pathname === "/about";

  return (
    <NavContainer>
      <NavContent>
        <HomeLink to="/" active={isHomePage}>
          <NavText home>홈</NavText>
        </HomeLink>
        <AboutLink to="/about" active={isAboutPage}>
          <NavText>서비스 소개</NavText>
        </AboutLink>
      </NavContent>
    </NavContainer>
  );
};

export default Navbar;
