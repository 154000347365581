import React from "react";
import styled from "@emotion/styled";
import antImage from "../assets/ant.png";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1440px;
  height: 100vh;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 375px;
    height: 812px;
    position: relative;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: 100%;
  height: calc(100vh - 48px);
  background: #ffffff;

  @media (max-width: 768px) {
    width: 375px;
    height: 764px;
    isolation: isolate;
    flex-grow: 1;
  }
`;

const Logo = styled.img`
  width: 200px;
  height: 182.35px;
  object-fit: contain;
`;

const Description = styled.p`
  width: 279px;
  height: 96px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;

  @media (max-width: 768px) {
    width: 327px;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 202px;
  height: 48px;
`;

const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 202px;
  height: 24px;
`;

const ContactText = styled.span`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;
`;

const Email = styled.a`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  text-decoration-line: underline;
  color: #007bff;
  width: 186px;
  height: 24px;
`;

const AboutPage = () => {
  return (
    <PageContainer>
      <ContentContainer>
        <Logo src={antImage} alt="Ant Logo" />
        <Description>
          다시 모두가 일상을 맘편히 보낼 수 있는 날, 해당 사이트는 종료됩니다.
          아무쪼록 날이 추우니 다들 감기 조심하시고 안전하고 따뜻하게 귀가하시길
          바라겠습니다.
        </Description>
        <ContactContainer>
          <ContactRow>
            <ContactText>제보/문의는 언제든 환영입니다.</ContactText>
          </ContactRow>
          <Email href="mailto:ordinarylifekr@gmail.com">
            ordinarylifekr@gmail.com
          </Email>
        </ContactContainer>
      </ContentContainer>
    </PageContainer>
  );
};

export default AboutPage;
