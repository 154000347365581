import "./styles/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import styled from "@emotion/styled";
import MapComponent from "./components/MapComponent.jsx";
import Navbar from "./components/Navbar.jsx";
import StoreList from "./components/StoreList.jsx";
import NewsSection from "./components/NewsSection.jsx";
import AboutPage from "./components/AboutPage.jsx";

const AppContainer = styled.div`
  min-height: 100vh;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 1441px) {
    padding: 0;
  }

  @media (max-width: 768px) {
    min-height: 100vh;
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  padding: 12px 16px;
  box-sizing: border-box;
  height: calc(100vh - 48px);
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;
  max-width: min(1440px, 100%);
  justify-content: center;
  align-items: flex-start;

  @media (min-width: 1441px) {
    max-width: 90%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 24px 16px;
    gap: 8px;
    width: 375px;
    height: auto;
    min-height: calc(100vh - 48px);
    isolation: isolate;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 80px;
  }
`;

const MainSection = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 70%;
  position: relative;
  @media (min-width: 1441px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 343px;
    max-width: 100%;
    height: 480px;
    order: 0;
    flex: none;
    align-self: center;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;
    transition: none;
  }
`;

const ChatSection = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 768px) {
    width: 400px;
    height: 100%;
    margin-left: 20px;
    margin-top: 0;
  }
`;

// const FloatingButton = styled.div`
//   position: fixed;
//   width: 64px;
//   height: 64px;
//   right: 16px;
//   bottom: 16px;
//   background: #007bff;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   box-shadow: 0px 28px 11px rgba(0, 0, 0, 0.01),
//     0px 16px 10px rgba(0, 0, 0, 0.05), 0px 7px 7px rgba(0, 0, 0, 0.09),
//     0px 2px 4px rgba(0, 0, 0, 0.1);
//   border-radius: 80px;
//   cursor: pointer;
//   z-index: 1000;

//   @media (max-width: 768px) {
//     bottom: 15.5px;
//   }
// `;

// const CoffeeIcon = styled.img`
//   width: 28px;
//   height: 40px;
// `;

const HomePage = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleSupportSelect = (location) => {
    setSelectedLocation(location);
  };

  return (
    <ContentContainer>
      <MainSection>
        <MapComponent
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
        />
      </MainSection>
      <ChatSection>
        <StoreList onSupportSelect={handleSupportSelect} />
        <NewsSection keyword={selectedLocation?.name} />
      </ChatSection>
    </ContentContainer>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AppContainer>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
};

export default App;
